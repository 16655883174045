import React from "react";
import { Button, Col, Container, Row } from "reactstrap";

const CTA3 = () => {
  return (
    <React.Fragment>
      <section className="bg-cta">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="header-title text-center">
                <h2>Open Source Encrypted Chat Software</h2>
                <p className="title-desc text-muted mt-3">
                  Work with the world's brightest engineers to create Convoa chat software, apps, and web features for users and decentralized chat servers.
                </p>
                <div className="mt-4">
                  <Button color="success" href="/#" className="mt-2">
                    Source Code
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default CTA3;
