import React from "react";
import { Col, Container, Row } from "reactstrap";

const ProcessCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className={props.number !== "04" ? "process-box process-border text-center" : "process-box text-center"}>
          <div className="process-count text-white mt-4">
            <h3 className="process-number">{props.number}</h3>
          </div>

          <div className="process-content bg-light mt-5 rounded p-4">
            <h5 className="fs-18">{props.step}</h5>
            <p className="text-muted mb-0">{props.caption}</p>
          </div>
        </div>
        {/* <!--end process-box--> */}
      </Col>
    </React.Fragment>
  );
}

// Process Data

const ProcessData = [
  {
    number: "01",
    step: "Download",
    caption: "Enjoy one of our Convoa chatroom client apps. Completely private, secure, encrypted, and FREE. ",
  },
  {
    number: "02",
    step: "Make Friends",
    caption: "Explore public chats and meet new people on Convoa. Find interesting and diverse topics & people.",
  },
  {
    number: "03",
    step: "Private Chats",
    caption: "Join or create your first encrypted chat on the network. Invite friends, family, and colleagues.",
  },
  {
    number: "04",
    step: "Community",
    caption: "Help grow the Convoa decentralized network of chat servers. Host your own public or private chat server.",
  },
];

const Process = () => {
  return (
    <React.Fragment>
      <section className="bg-process section" id="process">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <h3>Getting Started With Convoa</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                  Anyone can download Convoa software, apps, and use our chatroom clients to connect immediately, for free. We encourage you to try Convoa, check out the public chats, join private chats, and start a Convoa server of your own to help grow the decentralized, encrypted chatroom community.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-2">
            {/* Process Component Start */}

            {ProcessData.map((value, idx) => (
              <ProcessCard
                key={idx}
                number={value.number}
                step={value.step}
                caption={value.caption}
                isShow={value.isShow}
              />
            ))}

            {/* Process Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Process;
