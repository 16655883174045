import React from "react";
import CountUp from "react-countup";
import { Col, Container, Row } from "reactstrap";

import achivements from "../assets/images/achivements.jpg";

const CounterData = [
  {
    endPoint: 127,
    iconClass: "mdi mdi-heart",
    title: "Public Chatrooms",
  },
  {
    endPoint: 13594,
    iconClass: "mdi mdi-layers",
    title: "Messages Sent",
  },
  {
    endPoint: 1652,
    iconClass: "mdi mdi-cloud-download",
    title: "Downloads",
  },
  {
    endPoint: 12,
    iconClass: "mdi mdi-code-not-equal-variant",
    title: "Chat Servers",
  },
];

const Counter = () => {
  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="counter-box">
                <h4>Convoa Quick Stats</h4>
                <p className="text-muted mt-2">
                  Take a look at how the Convoa community is growing in 2024.
                </p>

                <div className="mt-4 mb-5">
                  <Row id="counter">
                    {/* Counter Component Start */}

                    {CounterData.map((val, key) => (
                      <Col key={key} md={6}>
                        <div className="counter-box mt-4">
                          <div className="d-flex">
                            <div className="counter-icon">
                              <i className={val.iconClass}></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h4 className="counter_value" data-target="485">
                                <CountUp end={val.endPoint} duration={2} />
                              </h4>
                              <p className="text-muted">{val.title}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}

                    {/* Counter Component Start */}
                  </Row>
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <div className="img">
                <img
                  src={achivements}
                  className="img-fluid box-shadow-lg rounded"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Counter;
